import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router, RoutesRecognized } from '@angular/router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { IsoLanguageValue, Link, ModalOptions, ModalRef, SiModalService, SiThemeService, ThemeType } from '@simpl/element-ng';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { ConfigService } from './shared/config.service';
import { EnvironmentSettings } from './environment-settings';
import { ThemeRuntimeSwitchService } from './services/theme-runtime-switch.service';
import { SiAuthGatewayService, THEME_COOKIE_NAME } from '@sisuite/ui';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, OnDestroy {

  @ViewChild('corporateInfo') corporateInfo!: TemplateRef<any>;

  settings: EnvironmentSettings;

  links: Link[] = [];
  langs: IsoLanguageValue[] = [
    { value: 'en', name: 'English' },
    { value: 'de', name: 'Deutsch' },
    { value: 'fr', name: 'French' },
    { value: 'es', name: 'Spanish' },
    { value: 'cs', name: 'Czech' },
    { value: 'da', name: 'Danish' },
    { value: 'nl', name: 'Dutch' },
    { value: 'fi', name: 'Finnish' },
    { value: 'it', name: 'Italian' },
    { value: 'hu', name: 'Hungarian' },
    { value: 'no', name: 'Norwegian' },
    { value: 'pl', name: 'Polish' },
    { value: 'pt', name: 'Portuguese' },
    { value: 'sk', name: 'Slovak' },
    { value: 'sv', name: 'Swedish' },
    { value: 'zh-Hans', name: 'Chinese simplified' },
    { value: 'zh-Hant', name: 'Chinese traditional' },
    { value: 'ko', name: 'Korean' },
    { value: 'ro', name: 'Romanian' },
    { value: 'tr', name: 'Turkish' },
    { value: 'el', name: 'Greek' }

  ];

  private destroyed$ = new Subject<void>();
  corporateInfoRef: ModalRef<unknown> | undefined;
  backgroundImageUrl = './assets/images/landing-page-digitalcity.webp';

  constructor(
    private configService: ConfigService,
    private titleService: Title,
    public activatedroute: ActivatedRoute,
    public translate: TranslateService,
    private cookieService: CookieService,
    private router: Router,
    private modalService: SiModalService,
    private themeRuntimeSwitchService: ThemeRuntimeSwitchService,
    private themeSwitcher: SiThemeService,
  ) {
    this.applyTheme();
    this.settings = this.configService.getConfig();
  }
  ngOnInit(): void {
    this.themeRuntimeSwitchService.hasSiemensBrandToggleSwitch().subscribe(on => {
      if (on){
        this.themeRuntimeSwitchService.switchToSiemensBrand();
        this.backgroundImageUrl = './assets/images/landing-page-buildingx.webp';
      }
    });
    // do initial navigation, caus its disabled at module level
    const currentHash = location.hash.replace('#/', '/');
    if (currentHash) {
      this.router.navigateByUrl(currentHash);
    } else {
      this.router.navigate(['/']);
    }

    this.router.events.pipe(takeUntil(this.destroyed$)).subscribe((event) => this.onRouterEvent(event));
    this.translate.onLangChange.pipe(takeUntil(this.destroyed$)).subscribe((event: LangChangeEvent) => this.onLanguageChanged(event));
  }

  private onLanguageChanged(event: LangChangeEvent): void {
    this.cookieService.set(this.settings.authCookieName, this.translate.currentLang, undefined, '/', this.settings.authCookieDomain);
    this.createFooterLinks();
  }

  private onRouterEvent(event: any): void {
    if (event instanceof RoutesRecognized) {
      const route = event.state.root.firstChild;
      const title = route?.data.headerTitle;
      this.titleService.setTitle(title);
      this.settings.landingpageTitle = title;
    }
  }

  private createFooterLinks(): void {
    this.links.length = 0;
    this.links = [];
    this.links.push({
      title: this.translate.instant('FOOTER.CORPORATE_INFO'),
      action: () => this.openCorporateInfo(),
    });
    this.addLink('FOOTER.PRIVACY_POLICY', this.translate.instant('FOOTER.PRIVACY_POLICY_URL'));
    this.addLink('FOOTER.COOKIE_POLICY', this.translate.instant('FOOTER.COOKIE_POLICY_URL'));
    this.addLink('FOOTER.ACCEPTABLE_USE_POLICY', this.translate.instant('FOOTER.ACCEPTABLE_USE_POLICY_URL'));
    this.addLink('FOOTER.DIGITAL_SERVICES_ACT', this.translate.instant('FOOTER.DIGITAL_SERVICES_ACT_URL'));
  }

  private addLink(titleKey: string, url: string): void {
    this.links.push({
      title: this.translate.instant(titleKey),
      href: url,
      target: url.startsWith('/#') ? '_self' : undefined
    });
  }

  private openCorporateInfo(): void {
    const config: ModalOptions = {
      ignoreBackdropClick: true,
      animated: true,
      keyboard: true,
      ariaLabelledBy: this.translate.instant('APP.CORPORATE-INFORMATION')
    };
    this.corporateInfoRef = this.modalService.show(this.corporateInfo, config);
  }

  closeDialog(): void {
    this.corporateInfoRef?.hide();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
  }

  applyTheme(): void {
    let theme: ThemeType = 'auto';
    const storedTheme = SiAuthGatewayService.isBehindGateway() ?
      localStorage.getItem(THEME_COOKIE_NAME) : this.cookieService.get(THEME_COOKIE_NAME);
    if (storedTheme === 'auto' || storedTheme === 'light' || storedTheme === 'dark') {
      theme = storedTheme as ThemeType;
    }
    this.themeSwitcher.applyThemeType(theme);
  }
}
